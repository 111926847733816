import React, { Component } from "react"
import {
  Paper,
  Grid,
  Box,
  makeStyles
} from '@material-ui/core';

import SEO from '../../components/seo'
import { LanguageSelect } from '../language-select'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100vw',
    height: '100vh',
    padding: theme.spacing(2)
  },
  paper: {
    maxWidth: 500,
    maxHeight: `calc(105vh / 2)`,
    padding: theme.spacing(4),
  },
}));

interface Props {
  children: Component | JSX.Element;
  pageName: string;
};

export const Layout = ({ children, pageName }: Props) => {
  const { root, paper } = useStyles()
  return (
    <Grid container justify="center" alignItems="center" className={root}>
      <SEO title={pageName} />
      <Grid item>
        <Paper className={paper}>
          {children}
        </Paper>
        <Box mt={1}>
          <LanguageSelect />
        </Box>
      </Grid>
    </Grid>
  )
}

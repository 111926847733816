import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { FormRenderProps } from 'react-final-form'

import { Field } from 'components/final-form/field'
import { TextField } from 'components/text-field'
import { ProgressButton } from 'components/progress-button'
import { PasswordField } from 'components/password-field'
import { ForgotPasswordLink } from 'components/forgot-password-link'

import { FormValues } from './registration-form'

type Props = FormRenderProps<FormValues>;

export const getFormTemplate = (buttonText: string) =>
  ({ handleSubmit, submitting, hasValidationErrors }: Props) => {
    const { t } = useTranslation()

    return (
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Field
              name='email'
              label={t('register.email')}
              component={TextField}
              type='email'
              disabled={submitting}
              fullWidth autoFocus required
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name='password'
              label={t('register.password')}
              component={PasswordField}
              type='password'
              disabled={submitting}
              fullWidth required
            />
          </Grid>
          <Grid item xs={12}>
            <ForgotPasswordLink />
          </Grid>
          <Grid item container justify='center'>
            <ProgressButton
              variant='contained'
              color='primary'
              type='submit'
              progress={submitting}
              disabled={submitting || hasValidationErrors}
            >
              {buttonText}
            </ProgressButton>
          </Grid>
        </Grid>
      </form>
    )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios';
import { navigate } from 'gatsby'

import { Layout } from 'components/layouts/login-layout'
import { LoginForm } from 'components/admin/login-form'
import ENDPOINTS from 'constants/endpoints'
import API_ENDPOINTS from 'constants/api-endpoints'
import { withErrorNotification } from 'components/hocs/with-error-notification'
import { UserContext } from 'components/global-context'

const LoginPage = ({ setError }) => {
  const { t } = useTranslation()
  const context = React.useContext(UserContext);

  const handleSubmit = ({ email, password }) =>
    axios
      .post(`${process.env.GATSBY_API_URL}${API_ENDPOINTS.LOGIN}`, {
        identifier: email,
        password: password,
      })
      .then(({ data: {user: { id, role, city, email }, jwt} }) => {
        context && context.setUser({
          id,
          email,
          token: jwt,
          role: role.name,
          cityId: city && city.id,
          cityName: city && city.name,
        });
        navigate(ENDPOINTS.ADMIN.HOME)
      })
      .catch(({ response }) => {
        setError(response)
      });

  return (
    <Layout pageName={t('page.login')}>
      <LoginForm onSubmitLogin={handleSubmit} />
    </Layout>
  )
}

export default withErrorNotification(LoginPage);

import React from 'react'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { object, string } from 'yup'

import { Form } from '../final-form/form'
import { getFormTemplate } from './form-template'

export const LoginForm = ({
  onSubmitLogin
}) => {
  const { t } = useTranslation()

  const schema = object().shape({
    email: string().email().required(),
    password: string().required(),
  });

  return (
    <>
      <Typography paragraph align='center' variant='h5'>
        {t('title.login')}
      </Typography>
      <Form
        onSubmit={onSubmitLogin}
        validationSchema={schema}
        initialValues={{ email: '', password: '' }}
        render={getFormTemplate(t('button.login'))}
      />
    </>
  );
}
